import ViewActions from "@/router/actions/home";
import PortfolioManager from "./views/PortfolioManager";
import RecentFiles from "./views/RecentFiles";
import InternetAlarms from "./views/InternetAlarms";
import FacetSearch from "./views/FacetSearch";
import WorkflowDashboard from "./views/WorkflowDashboard";
import ColorPaletteView from "./ColorPalette";
import RecordingDashboard from "./views/RecordingDashboard";
import SigningDashboard from "./views/SigningDashboard";

const noAccessMsg = "Access to this feature is not enabled.  Please contact your administrator.";

let pages = [
    {
        name: "home:recent-files",
        path: "recent-files",
        component: RecentFiles,
        meta: {
            label: "Recent Files",
            skipSaveOnLeave: true,
            showAddFileButton: true
        }
    },
    {
        name: "home:portfolio-manager",
        path: "portfolio-manager",
        component: PortfolioManager,
        meta: {
            label: "Portfolio Manager",
            logRouteAccess: true,
            skipSaveOnLeave: true,
            showAddFileButton: true
        }
    },
    {
        name: "home:alarms",
        path: "alarms",
        component: InternetAlarms,
        meta: {
            label: "Alerts",
            logRouteAccess: true,
            skipSaveOnLeave: true
        }
    },
    {
        name: "home:search",
        path: "search",
        component: FacetSearch,
        meta: {
            label: "Search",
            include: true,
            logRouteAccess: true,
            skipSaveOnLeave: true
        }
    },
    {
        name: "home:workflow-dashboard",
        path: "workflow-dashboard/:assignedTo?",
        component: WorkflowDashboard,
        meta: {
            label: "Workflow Dashboard",
            logRouteAccess: true,
            actions: ViewActions.WORKFLOW_DASHBOARD,
            skipSaveOnLeave: true,
            accessKey: "WorkflowDashboard_ScreenAccess",
            showAddFileButton: true
        }
    },
    {
        name: "home:recording-dashboard",
        path: "recording-dashboard",
        component: RecordingDashboard,
        meta: {
            label: "Recording Dashboard",
            include: true,
            logRouteAccess: true,
            skipSaveOnLeave: true,
            unauthorizedMessage: noAccessMsg,
            featureFlag: "recordingDocuments"
        }
    },
    {
        name: "home:signing-dashboard",
        path: "signing-dashboard",
        component: SigningDashboard,
        meta: {
            label: "Signing Dashboard",
            include: true,
            logRouteAccess: true,
            skipSaveOnLeave: true,
            unauthorizedMessage: noAccessMsg,
            featureFlag: "closingMenuEntry"
        }
    },
    {
        name: "home:color-palette",
        path: "/color-palette",
        component: ColorPaletteView,
        meta: {
            label: "Color Palette",
            skipSaveOnLeave: true
        }
    }
];

export default pages;
