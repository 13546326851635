<template>
    <div class="content-wrapper facet-search-container">
        <rq-banner
                :message="validationErrorMessage"
                variant="error"
                icon="fas fa-exclamation-triangle"
                :visible="validationErrorMessage.length > 0"
            />
        <div class="rq-container pt-3">
            <div class="row">
                <div class="col col-3 form-group">
                    <label class="form-control-label" for="txt_facet_search_term">Search:</label>
                    <div class="input-group">
                        <rq-search-input-group
                            id="txt_facet_search_term"
                            v-model="term"
                            show-search-button
                            variant="theme"
                            v-focus-select-all
                            placeholder="Search..."
                            tabindex="0"
                            @keyup="onSearch"
                            @search="onSearch"
                            @clear="onClearSearch"
                        />
                    </div>
                </div>
            </div>
        </div>
        <RqTabs
            v-if="hasLoaded"
            :tabs="tabItems"
            tab-pane-class="facet-tab"
            v-model="activeTab"
            @activate-tab="onTabSelected">
            <template
                v-for="(item, index) in gridConfigs"
                :key="index"
                #[item.ref]>
                <rqdx-action-data-grid
                    :ref="`${item.ref}Grid`"
                    :automation_id="`${item.ref}Grid`"
                    :config="item.gridConfig"
                    :dataSource="item.gridDataSource"
                    class="facet-grid"
                    @rowDoubleClick="onGridRowDoubleClick"
                    @reset="onResetFilters(item.ref)"
                    :export-file-name="item.exportFileName"
                    v-if="hasLoaded"
                    hide-search
                    rq-filters>
                    <template #toolbar>
                        <div class="alert alert-warning py-1" v-if="showFacetCountWarning(item.facet)" dismissible>
                            <strong>WARNING:</strong> The results are limited to the top {{maxRecords}} records, please add additional search criteria to refine your search.
                        </div>
                    </template>
                </rqdx-action-data-grid>
            </template>
        </RqTabs>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { OrderFacetDataSearchModel } from '@/shared/models/models';
    import DxGridUtils from "@/shared/utilities/DxGridUtils";

    export default {
        name: "FacetSearch",

        data() {
            return {
                term: "",
                gridConfigs: [],
                gridResults: {},
                orderFacetSearch: {},
                maxRecords: 500,
                validationErrorMessage: "",
                hasLoaded: false,
                activeTab: 0,
                itemKey: "ordersID",
            }
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                storedOrderFacetSearch: state => state.system.orderFacetSearch,
            }),
            tabItems() {
                return _.map(this.gridConfigs, item => {
                    let facetCount = this.getFacetCount(item.facet);
                    return {
                        automation_id: `btn_tab_${_.snakeCase(_.toLower(item.facet))}`,
                        title: `${item.facet} (${facetCount})`,
                        name: item.ref,
                        disabled: facetCount === 0
                    };
                });
            }
        },

        watch: {
            storedOrderFacetSearch: function (newValue, oldValue) {
                if (!_.isNil(newValue)) {
                    this.initializeSearch(newValue);
                    this.term = _.get(newValue, 'term', '');
                    this.orderFacetSearch.term = "";

                    // Augment the tab and re-search.
                    let config = this.getConfigByActiveTab();
                    let index = _.findIndex(this.gridConfigs, { facet: newValue.activeFacet });
                    this.activeTab = index;
                    this.onSearch();
                }
            },
        },

        created() {
            const self = this;

            self.initializeGridConfigs();
            self.initializeSearch(self.storedOrderFacetSearch);
            self.term = _.get(self.storedOrderFacetSearch, 'term', '');
            self.orderFacetSearch.term = "";

            self.onSearch();
        },

        methods: {

            getActiveGridInstance() {
                const self = this;
                let config = self.getConfigByActiveTab();
                let gridComponent = self.getGridComponent(config.ref);
                let gridInstance = _.get(gridComponent, 'gridInstance', {});

                return gridInstance;
            },

            getConfigById(id) {
                const self = this;

                if (id < 0) {
                    return {};
                }

                return self.gridConfigs[id];
            },

            getConfigByActiveTab() {
                const self = this;

                return self.getConfigById(self.activeTab);
            },

            getConfigByName(name) {
                const self = this;

                let index = _.findIndex(self.gridConfigs, { facet: name });

                return self.getConfigById(index);
            },

            getFacetCount(name) {
                const self = this;

                let facet = _.find(self.orderFacetSearch.facetCounts, { key: name });

                if (!facet) return 0;

                return facet.value;
            },

            showFacetCountWarning(name) {
                return _.gt(this.getFacetCount(name), this.maxRecords);
            },

            initializeSearch(search) {
                const self = this;

                let model = new OrderFacetDataSearchModel(search);

                model.facetCounts = _.map(self.gridConfigs, (config) => {
                    return {
                        key: config.facet,
                        value: 0,
                    }
                });

                self.orderFacetSearch = model;
                self.gridResults = {};
                self.hasLoaded = false;

                _.forEach(self.gridConfigs, (config) => {
                    config.term = "";
                });
            },

            initializeGridConfigs() {
                const self = this;
                let fileCellTemplate = (container, options) => {
                    if (options.data.hasAccess) {
                        $("<a/>").addClass("btn btn-sm btn-link btn-theme p-0 border-0")
                            .on("dxclick", function () {
                                self.$router.push(`/order/${options.data.ordersID}/oe`);
                            })
                            .append(`<span>${options.value}</span>`)
                            .appendTo(container);
                    } else {
                        $('<span/>').addClass("btn btn-sm btn-link btn-theme p-0 border-0 noaccess")
                            .attr('title', "You do not have access to this file")
                            .append(options.value)
                            .appendTo(container);
                    }
                };

                let filesConfig = {
                    ref: "files",
                    facet: "Files",
                    exportFileName: "RQO-Files-Search-Results",
                    term: "",
                };

                filesConfig.gridConfig = {
                    columns: [
                        { dataField: 'ordersID', allowSearch: false, visible: false, dataType: 'number', allowExporting: true, showInColumnChooser:false },
                        { dataField: 'fileNumber', allowSearch: true, caption: 'File #', cellTemplate: fileCellTemplate, dataType: 'string', allowHeaderFiltering: false, allowFiltering: true },
                        { dataField: 'otherCompaniesFileNumber', allowSearch: true, caption: 'Other File #', allowResizing: true, dataType: 'string', allowHeaderFiltering: false },
                        { dataField: 'status', allowSearch: false, dataType: 'string', allowHeaderFiltering: true, allowFiltering: true },
                        { dataField: 'salesPrice', allowSearch: true, dataType: 'string', format: '$#,##0.00', allowHeaderFiltering: false, alignment: 'right'},
                        DxGridUtils.dateColumn({
                            dataField: "closeDate",
                            visible: false
                        }),
                        DxGridUtils.dateColumn({
                            dataField: 'fundDate',
                            caption: 'Fund/Disbursement Date',
                        }),
                        { dataField: 'closingAgent', allowSearch: false, allowResizing: true, dataType: 'string', allowHeaderFiltering: false },
                        { dataField: 'underwriter', allowSearch: false, allowResizing: true, dataType: 'string', allowHeaderFiltering: true, allowFiltering: true },
                        { dataField: 'regionDisplay', caption:'Region', allowSearch: false, dataType: 'string', allowHeaderFiltering: true, allowFiltering: true },
                        { dataField: 'branchDisplay', caption:'Branch', allowSearch: false, dataType: 'string', allowHeaderFiltering: true, allowFiltering: true },
                        { dataField: 'hasAccess', allowSearch: false, visible: false, dataType: 'boolean', showInColumnChooser: false },
                    ],
                    remoteOperations: false,
                    selection: { mode: "single", showCheckBoxesMode: "never" }
                };
                filesConfig.gridDataSource = {
                    key: self.itemKey,
                    load () {
                        return Promise.resolve(self.gridResults[filesConfig.ref] || []);
                    },
                };

                let propertiesConfig = {
                    ref: "properties",
                    facet: "Properties",
                    exportFileName: "RQO-Properties-Search-Results",
                    term: "",
                };

                propertiesConfig.gridConfig = {
                    columns: [
                        { dataField: 'ordersID', allowSearch: false, visible: false, dataType: 'number', allowExporting: true, showInColumnChooser: false },
                        { dataField: 'fileNumber', allowSearch: false, caption: 'File #', sortIndex: 0, sortOrder: "asc", cellTemplate: fileCellTemplate, dataType: 'string', allowHeaderFiltering: false },
                        { dataField: 'address', allowSearch: true, caption: 'Property Address', allowResizing: true, dataType: 'string', allowHeaderFiltering: false },
                        { dataField: 'county', allowSearch: true, allowResizing: true, dataType: 'string', allowHeaderFiltering: false },
                        { dataField: 'subdivision', allowSearch: true, allowResizing: true, dataType: 'string', allowHeaderFiltering: false },
                        { dataField: 'status', allowSearch: false, dataType: 'string', allowHeaderFiltering: true },
                        { dataField: 'regionDisplay', caption:'Region', allowSearch: false, dataType: 'string', allowHeaderFiltering: true },
                        { dataField: 'branchDisplay', caption:'Branch', allowSearch: false, dataType: 'string', allowHeaderFiltering: true },
                        { dataField: 'hasAccess', allowSearch: false, visible: false, dataType: 'boolean', showInColumnChooser: false },
                    ],
                    remoteOperations: false,
                    selection: { mode: "single", showCheckBoxesMode: "never" }
                };
                propertiesConfig.gridDataSource = {
                    key: self.itemKey,
                    load () {
                        return Promise.resolve(self.gridResults[propertiesConfig.ref] || []);
                    },
                };

                let loansConfig = {
                    ref: "loans",
                    facet: "Loans",
                    exportFileName: "RQO-Loans-Search-Results",
                    term: "",
                };

                loansConfig.gridConfig = {
                    columns: [
                        { dataField: 'ordersID', allowSearch: false, visible: false, dataType: 'number', allowExporting: true, showInColumnChooser:false },
                        { dataField: 'fileNumber', allowSearch: false, caption: 'File #', sortIndex: 0, sortOrder: "asc", cellTemplate: fileCellTemplate, dataType: 'string', allowHeaderFiltering: false },
                        { dataField: 'loanNumber', allowSearch: true, dataType: 'string', caption: 'Loan #', allowHeaderFiltering: false },
                        { dataField: 'amount', allowSearch: false, dataType: 'number', format: '$#,##0.00', allowHeaderFiltering: false },
                        { dataField: 'lender', allowSearch: false, allowResizing: true, dataType: 'string', allowHeaderFiltering: true },
                        { dataField: 'status', allowSearch: false, dataType: 'string', allowHeaderFiltering: true },
                        { dataField: 'regionDisplay', caption:'Region', allowSearch: false, dataType: 'string', allowHeaderFiltering: true },
                        { dataField: 'branchDisplay', caption:'Branch', allowSearch: false, dataType: 'string', allowHeaderFiltering: true },
                        { dataField: 'hasAccess', allowSearch: false, visible: false, dataType: 'boolean', showInColumnChooser: false },
                    ],
                    remoteOperations: false,
                    selection: { mode: "single", showCheckBoxesMode: "never" }
                };
                loansConfig.gridDataSource = {
                    key: self.itemKey,
                    load () {
                        return Promise.resolve(self.gridResults[loansConfig.ref] || []);
                    },
                };

                let buyersSellersConfig = {
                    ref: "buyersSellers",
                    facet: "Buyers/Sellers",
                    exportFileName: "RQO-Buyers-Sellers-Search-Results",
                    term: "",
                };

                buyersSellersConfig.gridConfig = {
                    columns: [
                        { dataField: 'ordersID', allowSearch: false, visible: false, dataType: 'number', allowExporting: true, showInColumnChooser:false },
                        { dataField: 'fileNumber', allowSearch: false, caption: 'File #', sortIndex: 0, sortOrder: "asc", cellTemplate: fileCellTemplate, dataType: 'string', allowHeaderFiltering: false },
                        { dataField: 'role', allowSearch: false, allowResizing: true, dataType: 'string', allowHeaderFiltering: false },
                        { dataField: 'name1', allowSearch: true, allowResizing: true, dataType: 'string', allowHeaderFiltering: false },
                        { dataField: 'name2', allowSearch: true, allowResizing: true, dataType: 'string', allowHeaderFiltering: false },
                        { dataField: 'formalName', allowSearch: true, allowResizing: true, dataType: 'string', allowHeaderFiltering: false },
                        { dataField: 'businessName', allowSearch: true, allowResizing: true, dataType: 'string', allowHeaderFiltering: false },
                        { dataField: 'emailAddress1', allowSearch: true, allowResizing: true, dataType: 'string', allowHeaderFiltering: false },
                        { dataField: 'emailAddress2', allowSearch: true, allowResizing: true, dataType: 'string', allowHeaderFiltering: false },
                        { dataField: 'status', allowSearch: false, allowResizing: true, dataType: 'string', allowHeaderFiltering: true },
                        { dataField: 'regionDisplay', caption:'Region', allowSearch: false, dataType: 'string', allowHeaderFiltering: true },
                        { dataField: 'branchDisplay', caption:'Branch', allowSearch: false, dataType: 'string', allowHeaderFiltering: true },
                        { dataField: 'hasAccess', allowSearch: false, visible: false, dataType: 'boolean', showInColumnChooser: false },
                    ],
                    remoteOperations: false,
                    selection: { mode: "single", showCheckBoxesMode: "never" }
                };
                buyersSellersConfig.gridDataSource = {
                    key: self.itemKey,
                    load () {
                        return Promise.resolve(self.gridResults[buyersSellersConfig.ref] || []);
                    },
                };

                let policiesConfig = {
                    ref: "policies",
                    facet: "Policies",
                    exportFileName: "RQO-Policies-Search-Results",
                    term: "",
                };

                policiesConfig.gridConfig = {
                    columns: [
                        { dataField: 'ordersID', allowSearch: false, visible: false, dataType: 'number', allowExporting: true, showInColumnChooser:false },
                        { dataField: 'fileNumber', allowSearch: true, caption: 'File #', sortIndex: 0, sortOrder: "asc", cellTemplate: fileCellTemplate, dataType: 'string', allowHeaderFiltering: false },
                        { dataField: 'commitmentNumber', allowSearch: true, allowResizing: true, dataType: 'string', caption: 'Commitment #', allowHeaderFiltering: false },
                        { dataField: 'ownerPolicyNumber', allowSearch: true, allowResizing: true, dataType: 'string', caption: 'Owner Policy #', allowHeaderFiltering: false },
                        { dataField: 'mortgagePolicyNumber', allowSearch: true, allowResizing: true, dataType: 'string', caption: 'Mortgage Policy #', allowHeaderFiltering: false },
                        { dataField: 'underwriter', allowSearch: false, allowResizing: true, dataType: 'string', allowHeaderFiltering: true },
                        { dataField: 'status', allowSearch: false, allowResizing: true, dataType: 'string', allowHeaderFiltering: true },
                        { dataField: 'regionDisplay', caption:'Region', allowSearch: false, dataType: 'string', allowHeaderFiltering: true },
                        { dataField: 'branchDisplay', caption:'Branch', allowSearch: false, dataType: 'string', allowHeaderFiltering: true },
                        { dataField: 'hasAccess', allowSearch: false, visible: false, dataType: 'boolean', showInColumnChooser: false },
                    ],
                    remoteOperations: false,
                    selection: { mode: "single", showCheckBoxesMode: "never" }
                };
                policiesConfig.gridDataSource = {
                    key: self.itemKey,
                    load () {
                        return Promise.resolve(self.gridResults[policiesConfig.ref] || []);
                    },
                };
                let contactsConfig = {
                    ref: "contacts",
                    facet: "Contacts",
                    exportFileName: "RQO-Contacts-Search-Results",
                    term: "",
                };

                contactsConfig.gridConfig = {
                    columns: [
                        { dataField: 'ordersID', allowSearch: false, visible: false, dataType: 'number', allowExporting: true, showInColumnChooser:false },
                        { dataField: 'fileNumber', allowSearch: false, caption: 'File #', sortIndex: 0, sortOrder: "asc", cellTemplate: fileCellTemplate, dataType: 'string', allowHeaderFiltering: false },
                        { dataField: 'role', allowSearch: false, allowResizing: true, dataType: 'string', allowHeaderFiltering: false },
                        { dataField: 'firstName', allowSearch: true, allowResizing: true, dataType: 'string', allowHeaderFiltering: false },
                        { dataField: 'lastName', allowSearch: true, allowResizing: true, dataType: 'string', allowHeaderFiltering: false },
                        { dataField: 'fullName', allowSearch: true, allowResizing: true, dataType: 'string', allowHeaderFiltering: false },
                        { dataField: 'friendlyName', allowSearch: true, allowResizing: true, dataType: 'string', allowHeaderFiltering: false },
                        { dataField: 'emailAddress', allowSearch: true, allowResizing: true, dataType: 'string', allowHeaderFiltering: false },
                        { dataField: 'status', allowSearch: false, allowResizing: true, dataType: 'string', allowHeaderFiltering: true },
                        { dataField: 'regionDisplay', caption:'Region', allowSearch: false, dataType: 'string', allowHeaderFiltering: true },
                        { dataField: 'branchDisplay', caption:'Branch', allowSearch: false, dataType: 'string', allowHeaderFiltering: true },
                        { dataField: 'hasAccess', allowSearch: false, visible: false, dataType: 'boolean', showInColumnChooser: false },
                    ],
                    remoteOperations: false,
                    selection: { mode: "single", showCheckBoxesMode: "never" }
                };
                contactsConfig.gridDataSource = {
                    key: self.itemKey,
                    load () {
                        return Promise.resolve(self.gridResults[contactsConfig.ref] || []);
                    },
                };

                self.gridConfigs.push(filesConfig);
                self.gridConfigs.push(propertiesConfig);
                self.gridConfigs.push(buyersSellersConfig);
                self.gridConfigs.push(loansConfig);
                self.gridConfigs.push(policiesConfig);
                self.gridConfigs.push(contactsConfig);
            },

            onGridRowDoubleClick(e) {
                if (e.data.hasAccess) this.$router.push(`/order/${e.key}/oe`);
            },

            onResetFilters(name) {
                const self = this;

                // let gridInstance = self.getActiveGridInstance();

                // gridInstance.state(null);
                // gridInstance.searchByText(self.term);
            },

            onSearch: _.debounce(function (e) {
                const self = this;

                if (self.isValid()) {
                    self
                        .fetchData()
                        .finally(() => {
                            self.refreshActiveGrid();
                        });
                }
                else {
                    self.initializeSearch();
                }

            }, 500, {trailing: true, leading: false}),

            getGridComponent(ref) {
                const self = this;

                return _.get(self.$refs, `${ref}Grid[0]`, {});
            },

            refreshTabs() {
                const self =  this;
                let tabContainer = _.get(self.$refs, 'tabContainer', {});
                if (_.isFunction(tabContainer.updateTabs)) tabContainer.updateTabs();
            },

            refreshActiveGrid() {
                const self = this;

                let gridInstance = self.getActiveGridInstance();

                if (_.isEmpty(gridInstance)) return;

                self.$nextTick(() => {
                    gridInstance.updateDimensions();
                    //if (self.orderFacetSearch.term.length > 0) gridInstance.searchByText(self.orderFacetSearch.term);
                });
            },

            gridRefreshAndReload() {
                const self = this;

                let gridInstance = self.getActiveGridInstance();

                if (_.isEmpty(gridInstance)) return;

                if (_.isFunction(gridInstance, 'refresh')) gridInstance.refresh();

                let dataSource = gridInstance.getDataSource();

                if (_.isFunction(dataSource, 'reload')) dataSource.reload();
            },

            isValid() {
                const self = this;
                self.validationErrorMessage = "";
                // let selectedFacets = _.filter(self.facets, {selected: true});
                // if (selectedFacets.length < 1) {
                //     self.validationErrors.push('Please select at least one Facet to search against.');
                //     return false;
                // }
                // let selectedStatuses = _.filter(self.statuses, {selected: true});
                // if (selectedStatuses.length < 1) {
                //     self.validationErrors.push('Please select at least one Status to search against.');
                //     return false;
                // }
                if (self.term.length == 0) {
                    return false;
                }
                if (_.inRange(self.term.length, 1, 3)) {
                    self.validationErrorMessage = 'Please enter at least 3 characters to search for.';
                    return false;
                }
                return true;
            },

            onClearSearch() {
                const self = this;

                self.term = "";
                self.validationErrorMessage = "";
                self.initializeSearch();
            },

            fetchData_ORIG() {
                const self = this;

                // Get the known active tab.
                let config = self.getConfigByActiveTab();

                if (_.isEmpty(config)) return Promise.resolve(true);

                // Check if anything changed. This will let us know if we need to requery.
                let requery = false;

                if (config.term !== self.term) {
                    requery = true;
                }

                if (!requery) return Promise.resolve(true);

                // Let's refresh the screen.
                let orderFacetDataSearchRequest = new OrderFacetDataSearchModel({
                    term: self.term,
                    // facetSearchOptions: selectedFacets,
                    activeFacet: config.facet,
                    facetCounts:  self.orderFacetSearch.facetCounts,
                    includeFacetCounts: self.orderFacetSearch.term !== self.term, // this compares against the last actual search term, not the current tab's last search.
                    maxRecords: self.maxRecords,
                    // statusList: statusList
                });

                // Reset existing results (so they're not stagnant).
                self.gridResults[config.ref] = [];

                // If we are NOT getting the counts and we know there are already no results for this facet, avoid requerying data for no reason.
                if (!orderFacetDataSearchRequest.includeFacetCounts && self.getFacetCount(config.facet) == 0) {
                    return Promise.resolve(true);
                }

                let promise = new Promise((resolve) => {
                    self.hasLoaded = false;
                    resolve(true);
                })
                .then(() => {
                    return self.$nextTick();
                })
                .then(() => {
                    return self.$api.OrdersApi.getFacetDataSearchResults(orderFacetDataSearchRequest);
                });

                return self.$rqBusy.wait(promise)
                    .then(data => {

                        self.gridResults[config.ref] = _.get(data, config.ref);
                        self.orderFacetSearch = new OrderFacetDataSearchModel(data);
                        self.orderFacetSearch.includeFacetCounts = false;
                        config.term = data.term;
                    })
                    .catch(err => {
                        self.$toast.error({ message: err.message });
                    })
                    .finally(() => {
                        self.hasLoaded = true;
                    });
            },

            fetchData() {
                const self = this;

                // Get the known active tab.
                let config = self.getConfigByActiveTab();

                if (_.isEmpty(config)) return Promise.resolve(true);

                // Check if anything changed. This will let us know if we need to requery.
                let requery = false;

                if (config.term !== self.term) {
                    requery = true;
                }

                if (!requery) return Promise.resolve(true);

                // Let's refresh the screen.
                let orderFacetDataSearchRequest = new OrderFacetDataSearchModel({
                    term: self.term,
                    // facetSearchOptions: selectedFacets,
                    activeFacet: config.facet,
                    //facetCounts:  self.orderFacetSearch.facetCounts,
                    includeFacetCounts: true, //RQO-10061 : turn off purposely since we're getting all the details. self.orderFacetSearch.term !== self.term, // this compares against the last actual search term, not the current tab's last search.
                    maxRecords: self.maxRecords,
                    // statusList: statusList
                });

                // Reset existing results (so they're not stagnant).
                self.gridResults[config.ref] = [];

                // If we are NOT getting the counts and we know there are already no results for this facet, avoid requerying data for no reason.
                if (!orderFacetDataSearchRequest.includeFacetCounts && self.getFacetCount(config.facet) == 0) {
                    return Promise.resolve(true);
                }

                orderFacetDataSearchRequest.includeFacetCounts = false;

                let promise = new Promise((resolve) => {
                    self.hasLoaded = false;
                    resolve(true);
                })
                .then(() => {
                    return self.$nextTick();
                })
                .then(() => {
                    let promises = [];
                    //orderFacetDataSearchRequest.activeFacet = "Files"
                    promises.push(self.$api.OrdersApi.getFacetDataSearchResults(_.merge({}, orderFacetDataSearchRequest, { activeFacet: "Files"})));
                    //orderFacetDataSearchRequest.activeFacet = "Properties"
                    promises.push(self.$api.OrdersApi.getFacetDataSearchResults(_.merge({}, orderFacetDataSearchRequest, { activeFacet: "Properties"})));
                    //orderFacetDataSearchRequest.activeFacet = "Buyers/Sellers"
                    promises.push(self.$api.OrdersApi.getFacetDataSearchResults(_.merge({}, orderFacetDataSearchRequest, { activeFacet: "Buyers/Sellers"})));
                    //orderFacetDataSearchRequest.activeFacet = "Loans"
                    promises.push(self.$api.OrdersApi.getFacetDataSearchResults(_.merge({}, orderFacetDataSearchRequest, { activeFacet: "Loans"})));
                    //orderFacetDataSearchRequest.activeFacet = "Policies"
                    promises.push(self.$api.OrdersApi.getFacetDataSearchResults(_.merge({}, orderFacetDataSearchRequest, { activeFacet: "Policies"})));
                    //orderFacetDataSearchRequest.activeFacet = "Contacts"
                    promises.push(self.$api.OrdersApi.getFacetDataSearchResults(_.merge({}, orderFacetDataSearchRequest, { activeFacet: "Contacts"})));
                    return Promise.all(promises);
                    //return self.$api.OrdersApi.getFacetDataSearchResults(orderFacetDataSearchRequest);
                });

                return self.$rqBusy.wait(promise)
                    .then(data => {
                        let filesResults = data[0];
                        let propertiesResults = data[1];
                        let buyersSellersResults = data[2];
                        let loansResults = data[3];
                        let policiesResults = data[4];
                        let contactsResults = data[5];

                        self.gridResults['files'] = _.get(filesResults, 'files');
                        self.gridResults['properties'] = _.get(propertiesResults, 'properties');
                        self.gridResults['buyersSellers'] = _.get(buyersSellersResults, 'buyersSellers');
                        self.gridResults['loans'] = _.get(loansResults, 'loans');
                        self.gridResults['policies'] = _.get(policiesResults, 'policies');
                        self.gridResults['contacts'] = _.get(contactsResults, 'contacts');
                        self.orderFacetSearch = new OrderFacetDataSearchModel({
                            activeFacet: config.facet,
                            term: filesResults.term,
                            includeFacetCounts: false,
                            facetCounts: []
                                .concat(filesResults.facetCounts,
                                        propertiesResults.facetCounts,
                                        buyersSellersResults.facetCounts,
                                        loansResults.facetCounts,
                                        policiesResults.facetCounts,
                                        contactsResults.facetCounts,),
                        });
                        //self.orderFacetSearch.includeFacetCounts = false;
                        //  RQO-10061 : Force set all terms on initial search.
                        //config.term = data.term;
                        _.forEach(self.gridConfigs, (cfg) => { cfg.term = data[0].term });
                    })
                    .catch(err => {
                        self.$toast.error({ message: err.message });
                    })
                    .finally(() => {
                        self.hasLoaded = true;
                        self.setFirstTabWithDataActive();
                    });
            },

            fetchData_ALLATONCE() {
                const self = this;

                // Get the known active tab.
                let config = self.getConfigByActiveTab();

                if (_.isEmpty(config)) return Promise.resolve(true);

                // Check if anything changed. This will let us know if we need to requery.
                let requery = false;

                if (config.term !== self.term) {
                    requery = true;
                }

                if (!requery) return Promise.resolve(true);

                // Let's refresh the screen.
                let orderFacetDataSearchRequest = new OrderFacetDataSearchModel({
                    term: self.term,
                    // facetSearchOptions: selectedFacets,
                    activeFacet: config.facet,
                    facetCounts:  self.orderFacetSearch.facetCounts,
                    includeFacetCounts: true, //RQO-10061 : turn off purposely since we're getting all the details. self.orderFacetSearch.term !== self.term, // this compares against the last actual search term, not the current tab's last search.
                    maxRecords: self.maxRecords,
                    // statusList: statusList
                });

                // Reset existing results (so they're not stagnant).
                self.gridResults[config.ref] = [];

                // If we are NOT getting the counts and we know there are already no results for this facet, avoid requerying data for no reason.
                if (!orderFacetDataSearchRequest.includeFacetCounts && self.getFacetCount(config.facet) == 0) {
                    return Promise.resolve(true);
                }

                orderFacetDataSearchRequest.includeFacetCounts = false;

                let promise = new Promise((resolve) => {
                    self.hasLoaded = false;
                    resolve(true);
                })
                .then(() => {
                    return self.$nextTick();
                })
                .then(() => {
                    return self.$api.OrdersApi.getFacetDataSearchResults(orderFacetDataSearchRequest);
                });

                return self.$rqBusy.wait(promise)
                    .then(data => {

                        self.gridResults['files'] = _.get(data, 'files');
                        self.gridResults['properties'] = _.get(data, 'properties');
                        self.gridResults['buyersSellers'] = _.get(data, 'buyersSellers');
                        self.gridResults['loans'] = _.get(data, 'loans');
                        self.gridResults['policies'] = _.get(data, 'policies');
                        self.gridResults['contacts'] = _.get(data, 'contacts');
                        self.orderFacetSearch = new OrderFacetDataSearchModel(data);
                        self.orderFacetSearch.includeFacetCounts = false;
                        //  RQO-10061 : Force set all terms on initial search.
                        //config.term = data.term;
                        _.forEach(self.gridConfigs, (cfg) => { cfg.term = data.term });
                    })
                    .catch(err => {
                        self.$toast.error({ message: err.message });
                    })
                    .finally(() => {
                        self.hasLoaded = true;
                    });
            },

            onTabSelected(tabIndex) {
                const self = this;

                self.onSearch();
            },

            setFirstTabWithDataActive(){
                const self = this;

                let activeFacet = _.get(self, "storedOrderFacetSearch.activeFacet", null);

                if(activeFacet){
                    switch(activeFacet){
                        case "Files":
                            if(self.gridResults.files.length > 0){
                                self.activeTab = 0;
                                return;
                            }
                            break;
                        case "Properties":
                            if(self.gridResults.properties.length > 0){
                                self.activeTab = 1;
                                return;
                            }
                            break;
                        case "Buyers/Sellers":
                            if(self.gridResults.buyersSellers.length > 0){
                                self.activeTab = 2;
                                return;
                            }
                            break;
                        case "Loans":
                            if(self.gridResults.loans.length > 0){
                                self.activeTab = 3;
                                return;
                            }
                            break;
                        case "Policies":
                            if(self.gridResults.policies.length > 0){
                                self.activeTab = 4;
                                return;
                            }
                            break;
                        case "Contacts":
                            if(self.gridResults.contacts.length > 0){
                                self.activeTab = 5;
                                return;
                            }
                            break;
                    }
                }


                if(self.gridResults.files.length > 0){
                    self.activeTab = 0;
                }
                else if(self.gridResults.properties.length > 0){
                    self.activeTab = 1;
                }
                else if(self.gridResults.buyersSellers.length > 0){
                    self.activeTab = 2;
                }
                else if(self.gridResults.loans.length > 0){
                    self.activeTab = 3;
                }
                else if(self.gridResults.policies.length > 0){
                    self.activeTab = 4;
                }
                else if(self.gridResults.contacts.length > 0){
                    self.activeTab = 5;
                }
            },

        }
    }
</script>